<template>
  <div>
    <header-view
      :title="species.latin_name"
      :previous_page="admin ? '/speciesAdmin' : '/species'"
      :btnOptions="[
        {
          name: 'Edit Tree Species',
          icon: 'mdi-pencil',
          action: this.editSpecies,
        },
      ]" />
    <main-container class="ma-3">
      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Common Name</b>
        </v-flex>
        <v-flex md4>
          {{ species.alt_common_name || species.common_name }}
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Species ID</b>
        </v-flex>
        <v-flex md4>
          {{ species.species_uuid }}
        </v-flex>
      </v-layout>
      <v-layout row v-if="species.region && species.region.length > 0">
        <v-flex md2 class="text-xs-right pr-2">
          <b>Regions</b>
        </v-flex>
        <v-flex md4>
          <v-chip-group label="Region" multiple>
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <v-chip
              v-for="region in speciesRegions"
              :key="region.text"
              v-if="species.region.includes(region.value)">
              {{ region.text }}
            </v-chip>
            <!-- eslint-enable -->
          </v-chip-group>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>{{ toggleHeader }}</b>
        </v-flex>
        <v-flex md4>
          {{ species.toggleAttr }}
        </v-flex>
      </v-layout>
    </main-container>
  </div>
</template>

<script>
  import Species from '@/mixins/species.js';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';

  export default {
    name: 'AppSpeciesView',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    mixins: [Species],
    props: {
      admin: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      var toggleAttr = this.admin ? 'verified' : 'active';
      return {
        toggleAttr: toggleAttr,
        species: {
          common_name: '',
          latin_name: '',
          alt_common_name: '',
          tenantSpeciesUUID: '',
          species_uuid: '',
          toggleAttr: true,
        },
      };
    },
    computed: {
      toggleHeader: function () {
        return (
          this.toggleAttr.charAt(0).toUpperCase() + this.toggleAttr.slice(1)
        );
      },
    },
    async mounted() {
      var uuid = this.$route.params.uuid;
      if (this.admin) {
        this.species = await this.getSingleMasterSpecies(uuid);
        this.species.species_uuid = uuid;
      } else {
        this.species = await this.getSingleTenantSpecies(uuid);
      }
      console.log(this.species, uuid);
    },
    methods: {
      editSpecies: function () {
        if (this.admin) {
          this.$router.push('/speciesAdmin/edit/' + this.species.species_uuid);
        } else {
          this.$router.push('/species/edit/' + this.species.tenantSpeciesUUID);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
